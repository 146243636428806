/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
body {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.625;
}
@media only screen and (max-width: 1024px) {
  #home {
    width: 160px;
    height: 46px;
    margin-top: 14px;
    margin-left: 13px;
  }
  .cb-layout3 #home {
    margin-top: 10px;
    width: 80px;
    margin-right: 0;
  }
  #head {
    width: 96.09375%;
    margin-top: 35px;
    margin-left: 1.953125%;
  }
  #slogan {
    display: none;
  }
}
.container.container--footer {
  width: 96.09375%;
  padding: 16px 1.953125%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.953125%;
  margin-left: 1.953125%;
}
.area .part,
.area > .grid table {
  margin-right: 1.953125%;
  margin-left: 1.953125%;
  width: 96.09375%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.90625%;
  margin-left: 3.90625%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.1875%;
}
.area > .slim .tiny {
  width: 92.1875%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.area.main {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
@media only screen and (max-width: 1024px) {
  .area .unit.fold .body .less .part {
    padding: 16px 1.953125%;
  }
  .area .unit.fold .body .more .part:last-child {
    margin-bottom: 15px;
  }
  .area .unit.fold .part {
    width: 96.09375%;
    padding-right: 1.953125%;
    padding-left: 1.953125%;
  }
  .area .unit.fold .foot .ctrl .less,
  .area .unit.fold .foot .ctrl .more {
    height: 22px;
    padding: 16px 0;
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
  }
  .area .unit.fold {
    border-right: none;
    border-left: none;
  }
  .area .unit.flat {
    position: relative;
  }
  .area .unit.flat.wide {
    width: 46.09375%;
    margin-right: 1.953125%;
    margin-left: 1.953125%;
  }
  .area .unit.flat.wide .part {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-right: 4.07176907%;
    padding-left: 4.07176907%;
  }
  .area .unit.flat.wide .part.pict {
    padding: 0;
  }
  .area .unit.flat.slim {
    width: 46.09375%;
    margin-right: 1.953125%;
    margin-left: 1.953125%;
  }
  .area .unit.flat.slim .part {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-right: 4.07176907%;
    padding-left: 4.07176907%;
  }
  .area .unit.flat.slim .part.pict {
    padding: 0;
  }
  .area .unit.flat .part.text {
    padding: 15px;
  }
  .area .unit.seam.wide {
    width: 66.66666667%;
    margin-right: 0%;
    margin-left: 0%;
  }
  .area .unit.seam.wide .part {
    width: 88.28125%;
    margin-right: 2.9296875%;
    margin-left: 2.9296875%;
  }
  .area .unit.seam.slim {
    width: 33.33333333%;
    margin-right: 0%;
    margin-left: 0%;
  }
  .area .unit.seam.slim .part {
    width: 88.28125%;
    margin-right: 5.859375%;
    margin-left: 5.859375%;
  }
  .area .unit.seam.slim .part + .part {
    margin-top: -61px;
  }
  .area .unit.edge.wide {
    width: 96.09375%;
    margin-right: 1.953125%;
    margin-left: 1.953125%;
  }
  .area .unit.edge.wide .part {
    width: 75%;
    margin-right: 1.953125%;
    margin-left: 1.953125%;
  }
  .area .unit.edge.wide .part.tiny {
    width: 17.1875%;
  }
  .area .unit.edge.slim {
    min-height: 138px;
    width: 46.09375%;
    margin-right: 1.953125%;
    margin-left: 1.953125%;
  }
  .area .unit.edge.slim .part {
    width: 91.85646186%;
    margin-right: 4.07176907%;
    margin-left: 4.07176907%;
  }
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
h1 {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.2;
}
.area h2 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
.cb-layout3 .area h2 {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.2;
}
.area h3 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.pale {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
}
.container.container--footer {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.21428571;
}
.container.container--footer #services {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2.58333333;
}
/*# sourceMappingURL=./screen-medium.css.map */